import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'
import { RiInformationLine } from "react-icons/ri";

function AboutPage(props) {
    const url = window.location.href
    return (
        <div>
            <Helmet>
                <title>About Us - {utils.siteName}</title>
                <link rel="canonical" href={url} />
                <meta 
                    property="description" 
                    content=""/>
            </Helmet>
            <div className="flex justify-center space-x-3 items-center mb-7">
                <RiInformationLine className='text-secondary h-7 w-7'/>
                <h1 className='text-3xl'> About Us</h1>              
            </div>
            <div className="space-y-4 container mx-auto text-center">
                <p>
                    Welcome to {utils.siteName}, a platform dedicated to empowering the next generation of 
                    leaders, thinkers, and change-makers. We believe that youth are the driving force behind 
                    positive change in our communities and the world. Our mission is to provide tools, resources, 
                    and support that young people need to unlock their potential and create meaningful impact.
                </p>
                <p>
                    At {utils.siteName} we focus on nurturing personal growth, leadership skills, and social responsibility. 
                    Whether you’re looking for inspiration, guidance, or a community of like-minded individuals, we are here to 
                    help you on your journey toward making a difference.
                </p>
                <p>
                    Through engaging content, mentorship opportunities, and youth-led initiatives, we aim to inspire confidence, 
                    ignite passion, and spark action. We are committed to creating a space where young voices are heard, valued, 
                    and celebrated.
                </p>
                <p>
                    Join us as we build a brighter future, one empowered youth at a time. Together, we can achieve anything.
                </p>
            </div>
        </div>
    )
}

export default AboutPage