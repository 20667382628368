import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'
import { GrServices } from 'react-icons/gr'

function ServicesPage() {
    const url = window.location.href
    return (
        <div>
            <Helmet>
                <title>Programmes - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="flex justify-center space-x-3 items-center mb-7">
                <GrServices className='text-secondary h-7 w-7'/>
                <h1 className='text-3xl'> Programmes</h1>              
            </div>
            <div className="space-y-7 container mx-auto text-center">
                <p>
                    At Africa’s Promise, we believe in the limitless potential of young people to shape a 
                    better future. Our Youth Empowerment Programs are designed to equip the next generation 
                    with the skills, confidence, and opportunities they need to thrive in an ever-changing world.
                </p>
                <p>
                    Our programs focus on:
                </p>
                <div className='space-y-3'>
                    <h2 className='text-1xl font-bold text-secondary'>1. Leadership Development:</h2>
                    <p>
                        We provide workshops, mentorship, and hands-on experiences to help young individuals grow into
                        effective leaders. By cultivating qualities like communication, critical thinking, and decision-making, 
                        we prepare youth to take charge in their communities and beyond.
                    </p>
                </div>
                <div className='space-y-3'>
                    <h2 className='text-1xl font-bold text-secondary'>2. Skill Building:</h2>
                    <p>
                        From career readiness to personal development, our programs cover a wide range of skills essential 
                        for success. Through training sessions, online courses, and group activities, we help youth gain valuable 
                        skills in areas such as entrepreneurship, financial literacy, tech innovation, and more.
                    </p>
                </div>
                <div className='space-y-3'>
                    <h2 className='text-1xl font-bold text-secondary'>3. Community Engagement:</h2>
                    <p>
                        Empowered youth are active in their communities. Our programs encourage young people to take on projects that address 
                        social issues, volunteer for causes they care about, and collaborate with peers to create positive change in their local and 
                        global communities.
                    </p>
                </div>
                <div className='space-y-3'>
                    <h2 className='text-1xl font-bold text-secondary'>4. Mental and Emotional Well-being:</h2>
                    <p>
                        We recognize that youth empowerment is not just about skills, but also about building resilience and emotional 
                        intelligence. Our mental health initiatives support young people in developing a positive mindset, managing stress, 
                        and nurturing self-esteem.
                    </p>
                </div>
                <div className='space-y-5'>
                    <h2 className='text-1xl font-bold text-secondary'>5. Networking and Opportunities:</h2>
                    <p>
                        Through partnerships with organizations, businesses, and institutions, we connect youth with valuable resources and career 
                        opportunities. From internships and job shadowing to global exchange programs, our goal is to open doors and expand horizons.
                    </p>
                    <p>
                        By participating in our Youth Empowerment Programs, young people are not just preparing for the future—they are actively shaping it. 
                        We’re here to support, inspire, and help them realize their full potential, every step of the way.
                    </p>
                    <p>
                        Join us today, and let's create lasting change together!
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ServicesPage