import React from 'react'
// import { Link } from 'react-router-dom'
import { FaChevronRight, FaSquareInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaSquarePhoneFlip } from "react-icons/fa6";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Footer() {
    const currentDate = new Date()
    return (
        <div className='mt-24'>
            <div className="py-16 bg-customBlue text-white pb-24">
                <div className="grid md:grid-cols-3 gap-9 md:gap-16 p-4">                    
                    <div className="">
                        <p className="text-center text-3xl  mb-6 font-bold uppercase">Africa's Promise</p>
                        <div className="text-white text-center">
                            Africa’s Promise (AP) is an international NGO
                            registered with the aim of offering hope to
                            youth in Kenya and beyond. AP
                            is implementing and plans to implement a
                            number of programmes targeting the urban
                            youth who have been left vulnerable as a
                            result of poverty.
                        </div>
                    </div>
                    <div className="">
                        <p className=" text-3xl  uppercase mb-6 font-bold">Usefull Links</p>
                        <div className="space-y-2 text-white font-bold">
                            <p className=''><Link to="/" className='flex items-center gap-5' > <FaChevronRight/> Home</Link></p>
                            <p className=''><Link to="/about" className='flex items-center gap-5' > <FaChevronRight/>About</Link></p>
                            <p className=''><Link to="/help" className='flex items-center gap-5' > <FaChevronRight/> Help</Link></p>
                            <p className=''><Link to="/services" className='flex items-center gap-5' > <FaChevronRight/> Programmes</Link></p>
                            <p className=''><Link to="/events" className='flex items-center gap-5' > <FaChevronRight/> Events</Link></p>
                            <p className=''><Link to="/gallery" className='flex items-center gap-5' > <FaChevronRight/> Gallery</Link></p>
                            <p className=''><Link to="/contact" className='flex items-center gap-5' > <FaChevronRight/>Contact Us</Link></p>  
                        </div>
                    </div>
                    <div className="">
                        <p className=" text-3xl  uppercase mb-6 font-bold">Contact Us</p>
                        <div className="flex items-center gap-5 mb-4">
                            <div>
                                <FaSquarePhoneFlip className=' h-9 w-9'/>
                            </div>
                            <div>
                                <p className="text-white">+254 724 788 573</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-5 mb-4">
                            <div>
                                <MdMarkEmailUnread className=' h-9 w-9'/>
                            </div>
                            <div>
                                <p className="text-white">info@africaspromise.or.ke</p>
                                <p className="text-white">support@africaspromise.or.ke</p>
                            </div>
                        </div>
                        <div className='mb-4'>
                            <div className="flex items-center gap-5">
                                <div>
                                    <FaLocationDot className=' h-9 w-9'/>
                                </div>
                                <div>
                                    <p className="text-white">Nairobi, 8th Floor, Hazina Towers, Opposite Ambank House, Utali Lane/Monrovia Street.</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-6 mt-7 ">
                            <div className="">
                                <FaSquareInstagram className=' h-9 w-9'/>
                            </div>
                            <div className="">
                                <FaTiktok className=' h-9 w-9'/>
                            </div>
                            <div className="">
                                <FaFacebookSquare className=' h-9 w-9'/>
                            </div>
                        </div>
                        
                    </div>                    
                </div>
            </div>
            <div className="bg-black py-12">
                <p className="text-center text-2xl font-bold text-white">Copyright &copy; {currentDate.getFullYear()} rights reserved</p>
            </div>
        </div>
    )
}

export default Footer