import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'
import { MdHelp } from "react-icons/md";
import UnderConstruction from '../components/feedback/UnderConstrucion';

function HelpPage() {
    const url = window.location.href
    return (
        <div>
            <Helmet>
                <title>Help - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="flex justify-center space-x-3 items-center mb-7">
                <MdHelp className='text-secondary h-7 w-7'/>
                <h1 className='text-3xl'> Help</h1>              
            </div>
            <div className='container mx-auto'>
                <div className="collapse collapse-arrow border">
                    <input type="checkbox" defaultChecked />
                    <div className="collapse-title text-xl font-medium">
                        General Information
                    </div>
                    <div className="collapse-content border-t">
                        <div className="collapse collapse-arrow border mt-4">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                What does {utils.siteName} do?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                Where does {utils.siteName} operate?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How is {utils.siteName} funded?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow border">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                        Donations
                    </div>
                    <div className="collapse-content border-t">
                        <div className="collapse collapse-arrow border mt-4">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How do I make a Donation?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                What are the Payment methods?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                Can I set up recurring Payments for Donations?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How do I cancel my recurring Payments for Donations?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How do I keep track of what my Donation does?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow border">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                        Volunteering
                    </div>
                    <div className="collapse-content border-t">
                        <div className="collapse collapse-arrow border mt-4">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How can I become a volunteer?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow border">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                        Programs and Impact
                    </div>
                    <div className="collapse-content border-t">
                        <div className="collapse collapse-arrow border mt-4">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                What programs does {utils.siteName} run?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How does {utils.siteName} ensure donations are used effectively?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                Can I visit the projects my donations support?                              </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow border">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                        Transparency and Accountability
                    </div>
                    <div className="collapse-content border-t">
                        <div className="collapse collapse-arrow border mt-4">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                How does {utils.siteName} ensure transparency?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                        <div className="collapse collapse-arrow border">
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium">
                                Who oversees the operations of {utils.siteName}?
                            </div>
                            <div className="collapse-content border-t">
                                <div className="mt-4">
                                    <UnderConstruction/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default HelpPage