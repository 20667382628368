/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RiInformationFill } from "react-icons/ri";
import { ImHome2 } from "react-icons/im";
import { GrServices } from "react-icons/gr";
import { MdHelp } from "react-icons/md";
import { BsChatQuote } from "react-icons/bs";
import { RiGalleryFill } from "react-icons/ri";
import { FaCalendarDays } from "react-icons/fa6";

function Drawer() {
    const location = useLocation()
    function handleUlClick(event) {
        if (event.target.closest('a')) {
            const drawerToggle = document.getElementById("nav-drawer")
            drawerToggle.click()
        }
    }
    return (
        <div className="drawer-side">
            <label htmlFor="nav-drawer" className="drawer-overlay"></label>
            <div className="p-4 w-64 bg-customBlue  h-full">
                <div className="py-9">
                    <div className="flex justify-center">
                        {/* <img src={logo} alt="logo" className='h-20 rounded-md' /> */}
                    </div>
                </div>
                <ul 
                    onClick={handleUlClick}
                    className="menu text-white">
                    <li>
                        <Link 
                            to='/' 
                            className={location.pathname === '/' ? '': ''}>
                            <ImHome2 className='h-5 w-5 mr-3'/> Home</Link>
                    </li>
                    <li>
                        <Link 
                            to='/about' 
                            className={location.pathname === '' ? '': ''}>
                            <RiInformationFill className='h-5 w-5 mr-3'/> About Us</Link>
                    </li>
                    <li>
                        <Link 
                            to='/services' 
                            className={location.pathname === '' ? '': ''}>
                            <GrServices className='h-5 w-5 mr-3'/> Programmes</Link>
                    </li>
                    <li>
                        <Link 
                            to='/events' 
                            className={location.pathname === '' ? '': ''}>
                            <FaCalendarDays className='h-5 w-5 mr-3'/> Events</Link>
                    </li>
                    <li>
                        <Link 
                            to='/gallery' 
                            className={location.pathname === '' ? '': ''}>
                            <RiGalleryFill className='h-5 w-5 mr-3'/> Gallery</Link>
                    </li>
                    <li>
                        <Link 
                            to='/contact' 
                            className={location.pathname === '' ? '': ''}>
                            <BsChatQuote className='h-5 w-5 mr-3'/> Contact Us</Link>
                    </li>
                    <li>
                        <Link 
                            to='/help' 
                            className={location.pathname === '' ? '': ''}>
                            <MdHelp className='h-5 w-5 mr-3'/> Help</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Drawer