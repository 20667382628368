import React from 'react'
import {PiGearBold} from 'react-icons/pi'

function UnderConstruction() {
    return (
        <div className='flex gap-3 bg-base-300 items-center p-4 rounded-lg mb-3'>
            <PiGearBold className='h-10 w-10 md:h-7 md:w-7 animate-spin '/>
            <p>Hello! We are working on this Component, it will be available soon.</p>
        </div>
    )
}

export default UnderConstruction