import React from 'react'
import sports from '../../assets/sports.jpeg'
import mentoring from '../../assets/mentoring.jpeg'
import empowerment from '../../assets/empowerment.jpeg'

function Causes() {
    return (
        <div className='bg-customBlue py-12 text-white'>
            <div className="container mx-auto">
                <h1 className="text-center text-3xl font-bold mb-7">Our Popular Causes</h1>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
                    <div className="p-3 py-6 border-2 space-y-2">
                        <img src={sports} alt="Sporting Event" className='mb-4' />
                        <h2 className='font-bold text-lg'>Sports for Change</h2>
                        <p>
                            Sports is used as a vehicle for
                            change. Different sporting events are organized that target different age
                            categories with different messages and themes.
                            Examples include campaign against drug abuse, campaigns
                            on reproductive health issues
                        </p>
                    </div>
                    <div className="p-3 py-6 border-2 space-y-2">
                        <img src={mentoring} alt="Mentoring Session" className='mb-4' />
                        <h2 className='font-bold text-lg'>Mentorship</h2>
                        <p>
                            In this programme,
                            young people are exposed to leaders in their areas of
                            expertise. We have managed to link a lot of young people
                            to other young people who have succeeded in sports,
                            arts, education and technical fields such as mechanics
                        </p>
                    </div>
                    <div className="p-3 py-6 border-2 space-y-2">
                        <img src={empowerment} alt="EMpowering younth" className='mb-4' />
                        <h2 className='font-bold text-lg'>Youth Empowerment</h2>
                        <p>
                            We also run exchange programmes for
                            young people to enable them learn from each other.
                            These exchanges are run nationally and internationally to
                            allow young people on career development.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Causes