import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'
import { FaCalendarDays } from "react-icons/fa6";

function DonatePage() {
    const url = window.location.href
    return (
        <div className='-mb-24'>
            <Helmet>
                <title>Events - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="flex justify-center space-x-3 items-center mb-7">
                <FaCalendarDays className='text-secondary h-7 w-7'/>
                <h1 className='text-3xl'> Events</h1>              
            </div>
        </div>
    )
}

export default DonatePage