import React from 'react'
import { Link } from 'react-router-dom'
import hero from '../../assets/hero.png'

function Hero(props) {
    return (
        <div>
            <div
                className="hero carousel__img"
                style={{
                    backgroundImage: `url(${hero})`
                }}
                >
                <div className="hero-overlay bg-opacity-50"></div>
                <div className="hero-content justify-start text-white">
                    <div className="">
                        <h1 className='font-bold text-5xl mb-4'>We Can Make A Difference,</h1>
                        <h2 className='font-bold text-3xl mb-7'>One Life At A Time.</h2>
                        <div className='flex gap-2'>
                            <button 
                                onClick={() => props.scrollToElement()}
                                className='btn btn-warning'>Learn More</button>
                            <Link to='/services' className='btn btn-info'>Programmes</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero