import React, { useRef } from 'react'
import { Helmet } from 'react-helmet'
import typing from '../assets/typing2.jpg'
import utils from '../utils'
import { BsChatQuote } from "react-icons/bs";

function ContactPage() {
    const url = window.location.href
    const emailRef = useRef()
    const subjectRef = useRef()
    const textRef = useRef()

    function mailTo(body, subject){
        const destinationEmail = "info@regalbikes.com "
        window.location.href = `mailto:${destinationEmail}?subject=${subject}&body=${body}`;
        emailRef.current.value = ''
        subjectRef.current.value = ''
        textRef.current.value = ''
    }

    function submitHandler(e){
        e.preventDefault()
        const email = emailRef.current.value
        const subject = subjectRef.current.value
        const text = textRef.current.value
        const body = `from: ${email}  content: ${text}`
        mailTo(body, subject)
    }
    return (
        <div>
            <Helmet>
                <title>Contact Us - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="flex justify-center space-x-3 items-center mb-7">
                <BsChatQuote className='text-secondary h-7 w-7'/>
                <h1 className='text-3xl'> Contact Us</h1>              
            </div>
            <div className="container mx-auto">
                <div className="flex justify-center gap-7 mb-20">
                    <div className="hidden md:flex w-1/2">
                        <img src={typing} alt="person typing" className='rounded-lg' />
                    </div>
                    <div className='w-9/12 md:w-1/2'>
                        <form onSubmit={submitHandler}>
                            <div className="form-control mb-3">
                                <label className="label">
                                    <span className="label-text">Email*</span>
                                </label>
                                <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full " />
                            </div>
                            <div className="form-control mb-3">
                                <label className="label">
                                    <span className="label-text">Subject</span>
                                </label>
                                <input type="text" ref={subjectRef} name="email" className="input input-bordered w-full " />
                            </div>
                            <div  className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Text*</span>
                                </label>
                                <textarea ref={textRef} className="textarea textarea-bordered w-full" required cols={4} placeholder="Text"></textarea>
                            </div>
                            <div className='flex justify-center mt-5'>
                                <button className='btn btn-secondary' type='submit'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage