import React from 'react'
import orgpic from '../../assets/our-org.jpeg'

function About() {
    return (
        <div>
            <div className="container mx-auto px-2 py-8" id='how-to'>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                    <div className="w-full">
                        <img src={orgpic} alt="Organization Event" className="rounded-sm shadow-lg w-full"/>
                    </div>
                    <div className="w-full space-y-4">
                        <h2 className="text-2xl md:text-3xl font-bold text-blue-900 text-center">OUR ORGANIZATION</h2>
                        <div className="flex justify-center">
                            <div className="h-1 w-2/3 bg-blue-900 mb-4"></div>
                        </div>
                        <p className="text-gray-700 leading-relaxed">
                            Africa’s Promise (AP) is an international NGO registered with the aim of offering hope to youth in Kenya and beyond. The organization has its offices in Nairobi and is currently running programmes in Nairobi, Mombasa, and Kisumu cities in the Republic of Kenya. AP is implementing and plans to implement a number of programmes targeting the urban youth who have been left vulnerable as a result of poverty.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About