import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'

function PGNotFound() {
    const url = window.location.href
    return (
        <div>
            <Helmet>
                <title>Page Not Found - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div>
                <p className='text-center text-2xl pt-20'>The Page you are looking for could not be found!</p>
            </div>
        </div>
    )
}

export default PGNotFound