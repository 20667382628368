import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'
import { RiGalleryFill } from "react-icons/ri";
import img2 from '../assets/donate.jpeg'
import img1 from '../assets/sports.jpeg'
import img3 from '../assets/empowerment.jpeg'
import img4 from '../assets/mentoring.jpeg'
import img6 from '../assets/hero.png'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const images = [
    img6, img1, img2, img3, img4,
]

function GalleryPage() {
    const url = window.location.href
    return (
        <div>
            <Helmet>
                <title>Programmes - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="flex justify-center space-x-3 items-center mb-7">
                <RiGalleryFill className='text-secondary h-7 w-7'/>
                <h1 className='text-3xl'> Gallery</h1>              
            </div>
            <div className="container mx-auto">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3, 1080: 4}}>
                    <Masonry gutter='7.2px'>
                        {images?.map((item, key) => (
                            <div key={key}>
                                <img className="rounded-sm" src={item} alt="gallery"/>
                            </div>
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </div>
    )
}

export default GalleryPage