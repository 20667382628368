import React from 'react'
import health from '../../assets/health.jpeg'
import education from '../../assets/education.jpeg'
import mentorship from '../../assets/ymentorship.jpeg'
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa6";

function Programs() {
    return (
        <div className='bg-customLightBlue py-12'>
            <div className="container mx-auto px-2 py-8">
                <h2 className='text-2xl md:text-3xl font-bold text-center mb-7'>Here's List of our Programs:</h2>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-center mb-16">
                    <div className="card lg:card-side bg-base-100 shadow rounded-sm">
                        <figure>
                            <img src={health} alt="Health Program" className='h-40 w-auto'/>
                        </figure>
                        <div className="card-body">
                            <h2 className="card-title">Health Program</h2>
                            <p>Support those unable to access traditional healthcare.</p>
                        </div>
                    </div>
                    <div className="card lg:card-side bg-base-100 shadow rounded-sm">
                        <figure>
                            <img src={education} alt="Education Fundraising" className='h-40'/>
                        </figure>
                        <div className="card-body">
                            <h2 className="card-title">Education Fundraising</h2>
                            <p>Help supply resources to students who need them.</p>
                        </div>
                    </div>
                    <div className="card lg:card-side bg-base-100 shadow rounded-sm">
                        <figure>
                            <img src={mentorship} alt="Youth Mentership" className='h-40'/>
                        </figure>
                        <div className="card-body">
                            <h2 className="card-title">Youth Mentership</h2>
                            <p>Guide and support the young to reach their potential.</p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Link to={'/services'} className='btn btn-wide'>Programmes <FaArrowRight className='ml-5'/></Link>
                </div>
            </div>
        </div>
    )
}

export default Programs