import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo.png'

function NavBar() {
    return (
        <div className='container mx-auto'>
            <div className="navbar bg-base-100 p-3 nav-top">
                <div className="navbar-start gap-4">
                    <Link to='/' className="">
                        <img src={logo} alt='logo' className='h-16 rounded-md'/>
                    </Link>

                </div>
                <div className="navbar-center hidden md:flex">
                    <ul className="menu menu-horizontal px-1 font-extrabold text-secondary uppercase">
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about'>About Us</Link></li>
                        <li><Link to='/services'>Programmes</Link></li>
                        <li><Link to='/events'>Events</Link></li>
                        <li><Link to='/gallery'>Gallery</Link></li>
                        <li><Link to='/help'>Help</Link></li>
                        <li><Link to='/contact'>COntact Us</Link></li>
                    </ul>
                </div>
                <div className="navbar-end">
                    <ul className="menu menu-horizontal px-1">
                    
                    </ul>
                    <label htmlFor="nav-drawer">
                        <div className="btn btn-ghost btn-circle mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )
}

export default NavBar