const siteName = "Africas Promise"

const onProduction = false

const baseUrl = onProduction ? 'https://mytiko.co.ke' : 'http://127.0.0.1:8000'

const multipartHeader = { "Content-Type": "multipart/form-data" }

const toastError = "Something Went Wrong"

const toasterPosition = "bottom-center"

const toasterOptions = {
    className: "rounded-md border"
}


const utils = {
    siteName,
    multipartHeader,
    baseUrl,
    toasterPosition,
    toasterOptions,
    toastError,
}


export default utils