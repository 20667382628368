import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import HelpPage from './pages/HelpPage';
import NavBar from './components/nav/NavBar';
import Drawer from './components/nav/Drawer';
import Footer from './components/nav/Footer';
import utils from './utils';
import { Toaster } from 'react-hot-toast';
import PGNotFound from './pages/PGNotFound';
import ContactPage from './pages/ContactPage';
import ServicesPage from './pages/ServicesPage';
import DonatePage from './pages/DonatePage';
import { useEffect } from 'react';
import GalleryPage from './pages/GalleryPage';

function App(props) {
  const location = useLocation()
  useEffect(() => {
    const top = document.getElementById("page-top")
    top.scrollIntoView(true, {behavour: 'smooth'})
  }, [location])
  return (
    <div className="App">
      <div className="drawer drawer-end">
        <input id="nav-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <NavBar/>
          <div id='page-top' className=''></div>
          <div className='page'>
            <Routes>
              <Route path='/' element={<HomePage/>}/>
              <Route path='/about' element={<AboutPage/>}/>
              <Route path='/help' element={<HelpPage/>}/>
              <Route path='/contact' element={<ContactPage/>}/>
              <Route path='/services' element={<ServicesPage/>}/>
              <Route path='/events' element={<DonatePage/>}/>
              <Route path='/gallery' element={<GalleryPage/>}/>

              <Route path='*' element={<PGNotFound/>}/>
            </Routes>
          </div>
          
          <Footer/>
          <Toaster 
            position={utils.toasterPoisition}
            toastOptions={utils.toasterOptions}
          />
        </div>
        <Drawer/>
      </div>
    </div>
  );
}

export default App;