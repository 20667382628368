import React from 'react'
import { Helmet } from 'react-helmet'
import utils from '../utils'
import Hero from '../components/home/Hero'
import About from '../components/home/About'
import Causes from '../components/home/Causes'
import Programs from '../components/home/Programs'

function HomePage() {
    const url = window.location.href
    function scrollToElement() {
        const element = document.getElementById('how-to')
        element.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <div>
            <Helmet>
                <title>Home - {utils.siteName}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="mb-12">
                <Hero 
                    scrollToElement={scrollToElement}/>
            </div>
            <div className="mb-12">
                <About/>
            </div>
            <div className="mb-20">
                <Causes/>
            </div>
            <div className='mb-12'>
                <Programs/>
            </div>
        </div>
    )
}

export default HomePage